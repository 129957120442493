import React from "react";
import Layout from "../layouts/index.jsx";
import Header from "../layouts/header";
import IntroContainer from "../containers/home/intro";
import HealthFeatureContainer from "../containers/home/health-feature";
import MedicalFeatureContainer from "../containers/home/medical-feature";
import AppointmentContainer from "../containers/home/appointment";
import TeamContainer from "../containers/home/team";
import TestimonialContainer from "../containers/home/testimonial";
import BrandContainer from "../containers/global/brand";
import PricingContainer from "../containers/global/pricing";
import BlogAccordion from "../containers/home/blog-accordion";
import GoogleMapContainer from "../containers/global/map";
import Footer from "../layouts/footer";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import AboutData from "../data/about.json";
import PageTitleContainer from "../containers/global/page-title";
import SectionTitle from "../components/section-title";

const HomePage = () => {
	return (
		<React.Fragment>
			<Layout>
				<SEO title="Aquario Primary Home Care"/>
				<div className="wrapper home-default-wrapper">
					<Header/>
					<div className="main-content site-wrapper-reveal">
						<IntroContainer/>
						<div className="container">
							<div className="row">
								<div
									className="service-list-content"
									data-aos="fade-up"
									data-aos-duration="1200" style={{margin: "120px 0"}}
								>
									<SectionTitle
										classOption="text-center"
										title="<span>Get to</span> know us"
									/>

									<div style={{marginTop: -50}}
										 className="desc"
									>
										At Aquario Primary Home Care our mission is to place the best caregivers in your home with a care plan to fit you needs. We are a Family business passionate about our professional and personalized care , and work hard to meet and exceed your expectations.
									</div>
								</div>
							</div>
							<img alt={"Team"} src={"/team.jpg"}/>
						</div>
						{/*<HealthFeatureContainer />*/}
						{/*<MedicalFeatureContainer />*/}
						{/*<AppointmentContainer />*/}
						{/*<PricingContainer />*/}
						{/*<BlogAccordion />*/}
						{/*<GoogleMapContainer />*/}
					</div>
					<Footer/>
					<ScrollToTop/>
				</div>
			</Layout>
		</React.Fragment>
	);
};

export default HomePage;
