import React from "react";

async function postData(url = "", data = {}) {
	const response = await fetch(url, {
		method: "POST",
		mode: "cors",
		cache: "no-cache",
		credentials: "same-origin",
		headers: {
			"Content-Type": "application/json",
		},
		redirect: "follow",
		referrerPolicy: "no-referrer",
		body: JSON.stringify(data),
	});
	return response.json();
}

const ContactForm = () => {
	const [status, setStatus] = React.useState(false);
	const [form, setForm] = React.useState({});

	const send = (e) => {
		console.log(form);
		if (!form.name || !form.email || !form.message) {
			return e.preventDefault();
		}
		setStatus(true);
		postData("https://offset-mailer.vercel.app/api/contact", {
			...form,
			debug: false,
			to: "info@aquariohomecare.com",
		}).then(res => {
			console.log(res);
			setStatus(res);
		});
		e.preventDefault();
	};

	return (
		<form onSubmit={send}
			  className="contact-form-wrapper"
			  data-aos="fade-up"
			  data-aos-duration="1200"
		>
			<div className="row">
				<div className="col-md-4">
					<div className="form-group">
						<input
							className="form-control"
							type="text"
							name="name"
							placeholder="Your Name"
							required={true}
							onChange={x => setForm(e => ({...e, name: x.target.value}))}
						/>
					</div>
				</div>
				<div className="col-md-4">
					<div className="form-group">
						<input
							className="form-control"
							type="email"
							name="email"
							placeholder="Email Address"
							required={true}
							onChange={x => setForm(e => ({...e, email: x.target.value}))}
						/>
					</div>
				</div>
				<div className="col-md-4">
					<div className="form-group">
						<input
							className="form-control"
							type="text"
							name="subject"
							placeholder="Subject (optional)"
							onChange={x => setForm(e => ({...e, subject: x.target.value}))}
						/>
					</div>
				</div>
				<div className="col-md-12">
					<div className="form-group mb-0">
                        <textarea
							name="message"
							rows="5"
							placeholder="Write your message here"
							required={true}
							onChange={x => setForm(e => ({...e, message: x.target.value}))}
						/>
					</div>
				</div>
				{status && status.message && <p>{status.message}</p>}
				<div className="col-md-12 text-center">
					<div className="form-group mb-0">
						<button
							className="btn btn-theme btn-block"
							type="submit"
						>
							Send Message
						</button>
					</div>
				</div>
			</div>
		</form>
	);
};

export default ContactForm;
