import React from "react";

const ContactAddress = () => {
	return (
		<div className="contact-info-content">
			<div className="info-address">
				<h2 className="title">
					Come <span>Visit</span>
				</h2>
				<p>
					950 East Alton Gloor <br/>
					Brownsville, Texas <br/>
					78526
				</p>
			</div>
			<div className="brand-office">
				<div className="info-tem">
					<h6>Office Hours:</h6>
					<p>MON - FRI: 8:00AM - 5:00PM</p>
				</div>
				<div className="info-tem">
					<h6>Call directly:</h6>
					<p>(956) 544-7730</p>
				</div>
				<div className="info-tem">
					<h6>Whatsapp:</h6>
					<p>(956) 572-9480</p>
				</div>

			</div>
		</div>
	);
};

export default ContactAddress;
