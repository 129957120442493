import SocialIcon from "../../components/social-icon";
import {Link} from "react-router-dom";
import Newsletter from "../../components/newsletter";
import Logo from "../../components/logo";

const Footer = () => {
	return (
		<footer className="footer-area">
			{/*<div className="container">*/}
			{/*    <div className="row">*/}
			{/*        <div className="col-sm-12 col-md-12" >*/}
			{/*            <div className="widget-item">*/}
			{/*                <div className="about-widget">*/}
			{/*                    <Logo*/}
			{/*                        classOption="footer-logo"*/}
			{/*                        image={`${process.env.PUBLIC_URL}/img/logo-dark.png`}*/}
			{/*                    />*/}

			{/*                    <ul className="widget-contact-info">*/}
			{/*                        <li className="info-address">*/}
			{/*                            <i className="icofont-location-pin"></i>*/}
			{/*                            950 east alton Gloor,*/}
			{/*                            78526,*/}
			{/*                            Brownsville Texas.*/}
			{/*                        </li>*/}

			{/*                        <li className="info-phone">*/}
			{/*                            <i className="icofont-ui-call"></i>*/}
			{/*                            <a href="tel://+19565447730">*/}
			{/*                                9565447730*/}
			{/*                            </a>*/}
			{/*                        </li>*/}
			{/*                    </ul>*/}
			{/*                </div>*/}
			{/*            </div>*/}
			{/*        </div>*/}
			{/*    </div>*/}
			{/*</div>*/}
			<div className="footer-bottom">
				<div className="container">
					<div className="row text-center">
						<div className="col-sm-12">
							<div style={{marginBottom: 20}}>
								<Logo
									classOption="footer-logo"
									image={`${process.env.PUBLIC_URL}/img/logo-dark.png`}
								/>
							</div>
							<div className="widget-copyright">
								<p>
									&copy; 2021{" "}
									<span className="text-uppercase">All rights reserved</span>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
