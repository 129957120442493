import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";
import {getClosest, getSiblings, slideToggle, slideUp} from "../../../utils";
import SocialIcon from "../../social-icon";

const MobileMenu = ({show, onClose}) => {
	const onClickHandler = (e) => {
		const target = e.currentTarget;
		const parentEl = target.parentElement;
		if (
			parentEl?.classList.contains("menu-expand") ||
			target.classList.contains("menu-expand")
		) {
			const element = target.classList.contains("icon")
				? parentEl
				: target;
			const parent = getClosest(element, "li");
			const childNodes = parent.childNodes;
			const parentSiblings = getSiblings(parent);
			parentSiblings.forEach((sibling) => {
				const sibChildNodes = sibling.childNodes;
				sibChildNodes.forEach((child) => {
					if (child.nodeName === "UL") {
						slideUp(child, 1000);
					}
				});
			});
			childNodes.forEach((child) => {
				if (child.nodeName === "UL") {
					slideToggle(child, 1000);
				}
			});
		}
	};
	return (
		<div
			className={`offcanvas offcanvas-mobile-menu ${
				show ? "offcanvas-open" : ""
			}`}
		>
			<div className="inner">
				<div className="border-bottom mb-3 pb-3 text-end">
					<button className="offcanvas-close" onClick={onClose}>
						×
					</button>
				</div>
				{/*<div className="offcanvas-head mb-3">*/}
				{/*	<div className="header-top-offcanvas">*/}
				{/*		<p>*/}
				{/*			<i className="icofont-google-map"/>{" "}*/}
				{/*			950 East Alton Gloor*/}
				{/*			Brownsville, Texas*/}
				{/*			78526*/}
				{/*		</p>*/}
				{/*	</div>*/}
				{/*</div>*/}
				<nav className="offcanvas-menu">
					<img src={"/img/logo-dark.png"} alt={"Logo"} style={{marginBottom: 20}}/>
					<ul>
						<li>
							<NavLink exact to={process.env.PUBLIC_URL + "/"}>
								<span className="menu-text">Home</span>
							</NavLink>
						</li>

						<li>
							<NavLink to={process.env.PUBLIC_URL + "/services"}>
								services
							</NavLink>
						</li>

						<li>
							<NavLink to={process.env.PUBLIC_URL + "/contact"}>
								Contact
							</NavLink>
						</li>
					</ul>
				</nav>
				{/*<div className="offcanvas-social my-4">*/}
				{/*	<ul>*/}
				{/*		<li>*/}
				{/*			<SocialIcon*/}
				{/*				path="https://twitter.com/"*/}
				{/*				icon="icofont-twitter"*/}
				{/*			/>*/}
				{/*		</li>*/}
				{/*		<li>*/}
				{/*			<SocialIcon*/}
				{/*				path="https://www.facebook.com/"*/}
				{/*				icon="icofont-facebook"*/}
				{/*			/>*/}
				{/*		</li>*/}
				{/*		<li>*/}
				{/*			<SocialIcon*/}
				{/*				path="https://www.instagram.com/"*/}
				{/*				icon="icofont-instagram"*/}
				{/*			/>*/}
				{/*		</li>*/}
				{/*		<li>*/}
				{/*			<SocialIcon*/}
				{/*				path="https://rss.com/"*/}
				{/*				icon="icofont-rss-feed"*/}
				{/*			/>*/}
				{/*		</li>*/}
				{/*		<li>*/}
				{/*			<SocialIcon*/}
				{/*				path="https://www.youtube.com/"*/}
				{/*				icon="icofont-play-alt-1"*/}
				{/*			/>*/}
				{/*		</li>*/}
				{/*	</ul>*/}
				{/*</div>*/}

				<ul className="media-wrap" style={{marginTop: 50}}>
					<li className="media media-list">
                        <span className="media-icon">
                            <i className="icofont-google-map"/>
                        </span>
						<div className="media-content">
                            <span className="media-sub-heading">
                                address
                            </span>
							<span className="media-heading">
                                950 East Alton Gloor <br/>
                                Brownsville, Texas 78526
                            </span>
						</div>
					</li>

					<li className="media media-list">
					    <span className="media-icon">
					        <i className="icofont-clock-time"/>
					    </span>
						<div className="media-content">
					        <span className="media-sub-heading">
					            office hours
					        </span>
							<span className="media-heading">
					            MON - FRI: 8:00AM - 5:00PM
					        </span>
						</div>
					</li>

					<li className="media media-list">
                        <span className="media-icon">
                            <i className="icofont-ui-call"/>
                        </span>
						<div className="media-content">
                            <span className="media-sub-heading">
                                phone
                            </span>
							<a
								className="media-heading"
								href="tel:+(956) 544-7730"
							>
								(956) 544-7730
							</a>
						</div>
					</li>

					<li className="media media-list">
                        <span className="media-icon">
                            <i className="icofont-ui-call"/>
                        </span>
						<div className="media-content">
                            <span className="media-sub-heading">
                                whatsapp
                            </span>
							<a
								className="media-heading"
								href="tel:+(956) 572-9480"
							>
								(956) 572-9480
							</a>
						</div>
					</li>

					{/*<li className="media media-list">*/}
					{/*    <span className="media-icon">*/}
					{/*        <i className="icofont-envelope"/>*/}
					{/*    </span>*/}
					{/*	<div className="media-content">*/}
					{/*		<span className="media-sub-heading">email us</span>*/}
					{/*		<a*/}
					{/*			className="media-heading"*/}
					{/*			href="mailto:support@clenora.com.uk"*/}
					{/*		>*/}
					{/*			support@clenora.com.uk*/}
					{/*		</a>*/}
					{/*	</div>*/}
					{/*</li>*/}
				</ul>
			</div>
		</div>
	);
};

MobileMenu.propTypes = {
	show: PropTypes.bool,
	onClose: PropTypes.func,
};

export default MobileMenu;
