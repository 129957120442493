import React from "react";
import {NavLink, useLocation} from "react-router-dom";

const MainMenu = () => {
	const location = useLocation();
	const url = location.pathname;

	return (
		<nav>
			<ul className="main-menu">
				<li className={url === "/" && "active"}>
					<NavLink className="main-menu-link" exact to="/">
						Home
					</NavLink>
				</li>
				<li className={url === "/services" && "active"}>
					<NavLink
						className="main-menu-link"
						to={process.env.PUBLIC_URL + "/services"}
					>
						Services
					</NavLink>
				</li>
				<li className={url === "/contact" && "active"}>
					<NavLink
						className="main-menu-link"
						to={process.env.PUBLIC_URL + "/contact"}
					>
						Contact
					</NavLink>
				</li>
			</ul>
		</nav>
	);
};

export default MainMenu;
