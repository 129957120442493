import React from "react";

const GoogleMap = () => {
    return (
        <iframe
            title="this is title"
            className="contact-map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3586.667948603336!2d-97.50514988477921!3d25.97892828353859!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x866f96e7884cbb01%3A0x7f73f2e8fec39f38!2s950%20E%20Alton%20Gloor%20Blvd%2C%20Brownsville%2C%20TX%2078526!5e0!3m2!1sen!2sus!4v1625676634884!5m2!1sen!2sus"
            aria-hidden="false"
        ></iframe>
    );
};

export default GoogleMap;
