import React from "react";
import Layout from "../layouts/index.jsx";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
import PageTitleContainer from "../containers/global/page-title/index.jsx";
import ServiceContainer from "../containers/service-box/index.jsx";
import TestimonialContainer from "../containers/home/testimonial/index.jsx";
import BrandContainer from "../containers/global/brand/index.jsx";
import PricingContainer from "../containers/global/pricing/index.jsx";
import CallToAction from "../containers/global/call-to-action/index.jsx";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import TeamContainer from "../containers/home/team";
import IntroContainer from "../containers/home/intro";
import {NavLink} from "react-router-dom";
import SectionTitle from "../components/section-title";

const ServicePage = () => {
	return (
		<React.Fragment>
			<Layout>
				<SEO title="Aquario Primary Home Care | Services"/>
				<div className="wrapper">
					<Header/>
					<div className="main-content site-wrapper-reveal">
						<PageTitleContainer
							image="img/slider/main-slide-01.jpg"
							subTitle="Best solution for your heatlh"
							title="Services <span>That We Provide</span>"
						/>

						<div className="container">
							<div className="row">
								<div
									className="service-list-content"
									data-aos="fade-up"
									data-aos-duration="1200" style={{marginTop: 120}}
								>
									<SectionTitle
										classOption="text-center"
										title="<span>Our</span> Services"
									/>

									<div style={{marginTop: -50, marginBottom: 50}}
										 className="desc"
									>
										At Aquario Primary Home Care, we offer professional Care service to all of our patients. Our Providers take all your specific requests and demands into consideration and guarantee to cater to our patient need.
									</div>

									<div style={{marginBottom: 100, display: "flex", maxWidth: 660, margin: "0 auto", justifyContent: "space-between"}}
										 className="desc"
									>
										<div>
											<h4>Personal Attention</h4>
											<ol style={{listStyle: "disc"}}>
												<li>Bathing</li>
												<li>Dressing</li>
												<li>Exercise</li>
												<li>Grooming</li>
												<li>Routine hair/skin care</li>
												<li>Toileting</li>
											</ol>
										</div>

										<div>
											<h4>Home Management</h4>
											<ol style={{listStyle: "disc"}}>
												<li>Transfer</li>
												<li>Ambulation</li>
												<li>Cleaning</li>
												<li>Laundry</li>
												<li>Meal prepared</li>
												<li>Escort</li>
												<li>Shopping</li>
												<li>Assist. Med</li>
											</ol>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div style={{height: 100}}/>
						<SectionTitle
							classOption="text-center"
							title="<span>Medicaid and Medicare</span>"
						/>
						<SectionTitle
							classOption="text-center"
							title="<span style='font-size:20px;margin-top:-38px;color:#16186B'>SUPERIOR <br/> MOLINA <br/> DRISCOLL <br/> UNITED <br/> CIGNA HEALTH SPRING</span>"
						/>
						<SectionTitle
							classOption="text-center"
							title="<span>Primary Home Care</span>"
						/>
						<SectionTitle
							classOption="text-center"
							title="<span style='font-size:20px;margin-top:-38px;color:#16186B'>FAMILY CARE <br/> COMMUNITY ATTENDANT SERVICE</span>"
						/>
						<SectionTitle
							classOption="text-center"
							title="<span>All Ages Service</span>"
						/>


						<TeamContainer/>
						<TestimonialContainer/>


						<div className="container">
							<div className="row">
								<div
									className="service-list-content"
									data-aos="fade-up"
									data-aos-duration="1200" style={{marginTop: 120, textAlign: "center"}}
								>
									<h4 className="title" style={{marginBottom: 20}}>
										Interested in learning more about all the service we provide? Give us a call for <b>free consultation. </b>
										Also be sure to see our satisfied costumer testimonials below!</h4>
									<NavLink to={"/contact"}>
										<button className={"btn btn-danger"}>Contact Us</button>
									</NavLink>

								</div>
							</div>
						</div>
						{/*<ServiceContainer />*/}

						{/*<BrandContainer />*/}
						{/*<PricingContainer pricingClassOption="noborder-line" />*/}
						{/*<CallToAction />*/}
					</div>
					<Footer/>
					<ScrollToTop/>
				</div>
			</Layout>
		</React.Fragment>
	);
};

export default ServicePage;
